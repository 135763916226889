.navigation {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 2rem auto;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
  /* background: white; */
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.71);
  border-radius: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.4px);
  -webkit-backdrop-filter: blur(1.4px);
  border: 1px solid rgba(255, 255, 255, 0.11);
  visibility: hidden;
  overflow: hidden;
}
.navigation .Link {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.navigation .Link .icons {
  font-size: 32px;
  border-radius: 50%;
  background: greenyellow;
  color: black;
  padding: 0.5rem;
  border: 3px solid green;
}

.navigation .Link p {
  font-size: 11px;
}
@media screen and (max-width: 700px) {
  .navigation {
    visibility: visible;
  }

  .n3 {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 500px) {
  .navigation {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 10;
    padding: 1rem 0;
    /* background: white; */
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.71);
    border-radius: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.4px);
    -webkit-backdrop-filter: blur(1.4px);
    border: 1px solid rgba(255, 255, 255, 0.11);
  }
  .navigation .Link .icons {
    font-size: 42px;
    border-radius: 50%;
    background: greenyellow;
    color: black;
    padding: 0.5rem;
    border: 3px solid green;
  }
}
