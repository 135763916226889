@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,700;1,600&display=swap");

.paymentGetway {
  position: absolute;
  top: 0;
  bottom: 0;
  /* left: 50%; */
  background: rgb(20, 41, 14);
  width: 100%;
  /* transform: translate(-50%); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.PayForm {
  background: rgb(20, 41, 14);
  margin-top: 0;
  width: 50%;
  color: aliceblue;
}

.Pay {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
  /* background-color: #064635; */
  font-family: "Raleway", sans-serif;
}
.Pay .containerPay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: fit-content;
  padding: 20px;
  padding-bottom: 5px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(51px);
  -webkit-backdrop-filter: blur(51px);
  border: 0.5px solid rgba(52, 233, 61, 0.247);
  width: 300px;
  height: 600px;
  padding-bottom: 2rem;
}
.Pay .circles {
  position: absolute;
  width: 300px;
  display: flex;
  align-items: center;
  margin-top: -320px;
  left: 50%;
  transform: translateX(-50%);
}
.Pay .circle1 {
  width: 150px;
  height: 150px;
  background-color: #f0bc628a;
  border-radius: 50%;
  z-index: -1;
}
.Pay .circle2 {
  margin-top: 60px;
  width: 150px;
  height: 150px;
  background-color: #62b7f08a;
  border-radius: 50%;
  z-index: 1;
}
.Pay .image img {
  /* margin-top: 30px; */
  width: 100px;
  height: 100px;
  background-color: rgb(206, 206, 206);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Pay .hello {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}
.Pay .hello p {
  color: white;
  opacity: 0.4;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.Pay .hello span {
  font-size: 12px;
  margin-left: 2px;
  margin-bottom: -6px;
  opacity: 1;
}
.Pay .name {
  color: white;
  font-weight: 700;
  font-size: 18px;
}
.Pay .front {
  color: white;
  opacity: 0.4;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.Pay .portfolio {
  position: relative;
  text-align: center;
  font-size: 20px;
  width: 95%;
  height: 70%;
  margin: 20px;
  color: white;
  padding: 20px;
  background: rgba(255, 255, 255, 0.987);
  border-radius: 26px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(31px);
  -webkit-backdrop-filter: blur(31px);
  border: 0.5px solid rgba(45, 218, 48, 0.247);
  overflow-y: scroll;
  box-shadow: inset 0px -10px 15px rgba(0, 0, 0, 0.178);
}
.Pay .portfolio::-webkit-scrollbar {
  display: none;
}
.Pay .project1 {
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.838);
  width: 95%;
  height: 50%;
  margin-top: 20px;
  border-radius: 15px;
}

.PayBtn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.PayBtn button {
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: none;
  padding: 0 0.5rem;
  outline: none !important;
  margin: auto;
  /* width: 200px; */
  /* height: rem; */
  /* padding: 0.5rem; */
  display: flex;
  font-size: 10px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
}
.PayBtn img {
  width: 40px;
  height: 20px;
  /* object-fit: contain; */
}

/* .Btn-Back { */
/* padding: 0.5rem !important; */
/* font-size: 20px !important; */
/* } */
@media screen and (max-width: 600px) {
  .PayForm {
    background: rgb(20, 41, 14);
    margin-top: 0;
    width: 100%;
    color: aliceblue;
  }
}
