.Faq div + div {
  clear: both;
}

.Faq p {
  line-height: 1.4em;
  color: #9e9e9e;
}

.Faq .faq-title {
  font-size: 15px;
  text-align: center;
  /* margin: 0.4em 0; */
}


.Faq .faq-list > div {
  border-bottom: 0.07em solid #ededed;
  padding: 1.5em 0em;
}

.Faq .faq-list > div:last-child {
  border: unset;
}

.Faq details > summary {
  list-style: none;
}
.Faq details > summary::-webkit-details-marker {
  display: none;
}

.Faq summary {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease;
}

.Faq summary:hover {
  color: #d32092;
}

.Faq details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 55px;
  }
}

.Faq details[open] summary {
  color: #d32092;
}

.Faq details[open] p {
  border-left: 2px solid #d32092;
  margin-left: 30px;
  padding-left: 10px;
  opacity: 100;
  transition: all 3s ease;
  font-size: 14px;
}

.Faq details[open] summary:after {
  content: "-";
  font-size: 20px;
  margin: -33px 0.35em 0 0;
  font-weight: 200;
}

.Faq .faq-body {
  width: 100%;
  /* margin: 4em auto; */
  /* box-shadow: 0px 0px 16px #5b5b5b; */
  border-radius: 0.2em;
  padding: 1rem;
  background-color: #fff;
}

.Faq .faq-list {
  width: 100%;
  margin: auto;
  padding: 0;
}

.Faq summary::-webkit-details-marker {
  display: none;
}

.Faq summary:after {
  background: transparent;
  border-radius: 0.3em;
  content: "+";
  color: #d32092;
  float: left;
  font-size: 1.8em;
  font-weight: bold;
  margin: -0.3em 0.65em 0 0;
  padding: 0;
  text-align: center;
  width: 25px;
}
