.Categories {
  margin: auto;
  width: 100%;
  position: relative;
  /* text-align: center; */
}
.Categories .return{
    top: 1.5rem;
    left: 0;
}
