.Header {
  position: relative;
  z-index: 3;
  background-color: var(--theme-bg-color);
}
.Link {
  text-decoration: none;
}
.Header .cover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  /* padding: 0.1rem 1rem; */
  z-index: 3;
}

.Header .cover .logo .Link img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  /* background: gray; */
}

.Header .search {
  /* background: black; */
  /* padding: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex: 1; */
  width: 50%;
  position: relative;
}
.Header .search input {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  text-align: center;
  border-radius: 50px;
  color: var(--active-color);
  outline: none;
  font-size: 12px;
  border: none;
}
.Header .searchIcon {
  position: absolute;
  right: 1rem;
  background: white;
  border-radius: 50%;
  padding: 0.2rem;
  cursor: pointer;
  border: 1px solid rgba(128, 0, 128, 0.471);
}
.Header .searchData {
  position: absolute;
  top: 100%;
  width: 70%;
  /* height: 100%; */
  /* border: 1px solid gray; */
  border-radius: 10px;
  background: gainsboro;
  overflow: scroll;
  transition: 0.5s ease-in-out;
  color: black;
  z-index: 10;
}
.Header .s-component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  text-align: center;

  /* background: black; */
  /* gap: 1rem; */
  border: 1px soild black;
  padding: 0.5rem;
  margin: 0.5rem;
  align-items: start;
}
.Header .s-component h3 {
  font-size: 10px;
  color: green;
  text-align: left;
}

.Header .s-component-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  background: black;
  border-radius: 50%;
}

.HideBar {
  visibility: hidden;
  opacity: 0;
}

.Header .sellers h2 {
  font-size: 18px;
  padding: 0.6rem;
  background: green;
  color: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover2 {
  /* background: white; */
  /* height: 3rem; */
  margin: auto;
  /* width: 80%; */
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  transition: 0.5s ease-in-out;
}
.cover2_active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  /* background: white; */
  /* height: 3rem; */
  margin: auto;
  /* width: 80%; */
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  transition: 0.5s ease-in-out;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
}

.categoriesCover {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: black; */
  width: 100%;
}
.categories {
  position: relative;
  /* padding: 0 1rem; */
  display: flex;
  align-items: center;
  /* background: red; */
  justify-content: center;
  width: 100px;
  /* width: 100%; */
}
.userName {
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(103, 167, 7);
}
.userName img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
  cursor: pointer;
}
/* .userName:hover .menuIcon {
  visibility: visible;
  width: 100px;
} */
.menuIcon {
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  color: white;
  padding: 0.2rem;
  /* margin-left: 7rem; */
  cursor: pointer;
  width: 100px;
  top: 3.1rem;
  left: 0;
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  /* width: 100px; */
  /* visibility: hidden; */
  transition: 0.3s ease-in-out;
}
.menuIcon button {
  padding: 0.3rem;
  font-size: 12px;
  background: white;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid gray;
}
li {
  list-style: none;
}
.noneUser {
  font-size: 30px;
  background: green;
  border-radius: 50%;
  padding: 0.2rem;
  color: white;
  border: 2px solid gray;
}

/* .categories button {
  padding: 0.5rem;
  font-size: 18px;
  margin-left: 2rem;
  background: none;
  border: none;
  font-weight: bolder;
  color: purple;
} */
.DropDown {
  position: absolute;
  /* left: 3rem; */
  top: 180%;
  width: 240px;
  /* height: 300px; */
  z-index: 1;
  background: gainsboro;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
}

.DropDown .DropDown-item {
  display: flex;
  gap: 0.5rem;
  /* margin: 0 5rem; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* gap: 1rem; */
  /* justify-items: left; */
  align-items: center;
  /* background: black; */
  /* gap: 1rem; */
  /* border: 1px soild black; */
  padding: 0 0.5rem;
  margin: 0.5rem 0;
  align-items: flex-start;
  cursor: pointer;
}
.DropDown .DropDown-item h3 {
  font-size: 10px;
  letter-spacing: 0.1px;
  color: purple;
  text-align: left;
}

.DropDown .DropDown-item img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-left: 10px;
  user-select: none;
  border-radius: 50px;
}

.Header .nav-links ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 0.3rem;
  /* background: black; */
}

.Header .nav-links ul li .Link {
  padding: 1rem;
  background: rgba(245, 245, 245, 0.35);
  border: 1px solid rgba(8, 141, 3, 0.134);
  list-style: none;
  color: green;
  border-radius: 50px;
  /* background: black; */
}

.headerNav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.headerNav ul li {
  padding: 0 1rem;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: green;
}
.linkTo {
  font-size: 15px;
  font-weight: bolder;
  color: green;
  text-decoration: none;
  /* background-color: var(--bg); */
  transition: 0.5s ease-in-out;
  border-radius: 5px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.4rem;
  display: flex;
  letter-spacing: 3px;
}

.cart {
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 8;
}
.cart span {
  padding: 0.3rem;
  width: 30px;
  height: 30px;
  top: -1.5rem;
  right: -1px;
  /* background-color: #360c49; */
  background-color: none;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: green;
  border: 1px solid green;
  z-index: 100;
}
.cartButton {
  cursor: pointer;
  position: relative;
  padding: 0.5rem;
  display: flex;
}
.cart-icon {
  z-index: 10;
  /* border-radius: 50%; */
  color: green;
  font-size: 25px;
}

@media screen and (max-width: 830px) {
  .Header .cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    /* padding: 0.1rem 1rem; */
    z-index: 3;
  }
  .Header .nav-links ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem 0;
    /* background: black; */
  }

  .Header .nav-links ul li .Link {
    padding: 10px;
    font-size: 10px;
    background: rgba(245, 245, 245, 0.35);
    border: 1px solid rgba(8, 141, 3, 0.134);
    list-style: none;
    color: green;
    border-radius: 50px;
    /* background: black; */
  }
  .Header .sellers h2 {
    font-size: 10px;
    padding: 0.2rem;
    background: green;
    color: white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 425px) {
  .Header .nav-links ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem 0;
    /* background: black; */
  }

  .Header .nav-links ul li .Link {
    padding: 3px;
    font-size: 8px;
    background: rgba(245, 245, 245, 0.35);
    border: 1px solid rgba(8, 141, 3, 0.134);
    list-style: none;
    color: green;
    border-radius: 50px;
    /* background: black; */
  }
  .Header .sellers h2 {
    font-size: 10px;
    padding: 0.2rem;
    background: green;
    color: white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
