/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");

/* Function to convert px units to rem */
/* https://css-tricks.com/snippets/sass/px-to-em-functions/ */
@function rem($pixels, $context: 16) {
  @return ($pixels / $context) * 1rem;
}

.Testimonials {
  color: var(--white);
  background-color: var(--bg-blue);
  font-family: var(--base-font);
  /* Center the container */
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  width: 80%;
}
.Testimonials h3 {
  text-align: center;
  margin: 1rem auto;
  width: 50%;
  padding: 0.5rem;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.Testimonials img {
  max-width: 100%;
  vertical-align: bottom;
}

/* Card */
.Testimonials .testimonial {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px 25px 25px;
  flex-grow: 1;
  flex-basis: 280px;
  max-width: 360px;
  position: relative;
}

/* Fonts */
.Testimonials .testimonial__text {
  font-weight: normal;
  font-size: rem(14);
  line-height: 21px;
  margin-bottom: rem(25);
}

.Testimonials .testimonial__job {
  font-weight: normal;
  font-size: rem(8);
  line-height: 10px;
  letter-spacing: 0.5px;
}

.Testimonials .testimonial__name {
  font-weight: bold;
  font-size: rem(10);
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

/* Bottom layout */
.Testimonials .testimonial__img {
  display: inline-block;
  margin-right: 10px;
}

.Testimonials .testimonial__img img {
  width: 50px;
  height: 50px;
  vertical-align: bottom;
  border-radius: 50%;
}

.Testimonials .testimonial__info {
  display: inline-block;
}

/* General Styles */
.Testimonials .container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Specific Styles */
.Testimonials .container--testimonials {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 40px;
  justify-content: center;
  padding: 45px 45px 90px;
}

@media (min-width: 78.125em) {
  .Testimonials .container--testimonials {
    padding: 0;
    max-width: 1160px;
  }
}

@media screen and (max-width: 700px) {
  .Testimonials {
    color: var(--white);
    background-color: var(--bg-blue);
    font-family: var(--base-font);
    /* Center the container */
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    width: 100%;
  }
  .Testimonials h3 {
    text-align: center;
    margin: 1rem auto;
    width: 50%;
    padding: 0.5rem;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .Testimonials img {
    max-width: 100%;
    vertical-align: bottom;
  }

  /* Card */
  .Testimonials .testimonial {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 40px 25px 25px;
    flex-grow: 1;
    /* flex-basis: 280px; */
    max-width: 100%;
    position: relative;
  }
}
