@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;800&display=swap");

.Error {
	min-height: 100vh;
	display: grid;
	place-items: center;
	font-family: "Heebo", sans-serif;
	font-weight: 400;
	--black: #424242;
	--dark: #111111;
	--white: #d3d3d3;
}

.Error .container {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
	background-color: var(--dark);
}

.Error .dark-layer {
	position: absolute;
	top: -25%;
	left: -25%;
	width: 150%;
	height: 150%;
	z-index: 3;
	background-color: var(--dark);
	mask-image: radial-gradient(600px 900px at 50% 50%, transparent, #000 36%);
	position: relative;
	transform-origin: top center;
	animation: move-spot 4s infinite ease-in-out;
}
@keyframes move-spot {
	0%,
	100% {
		transform: rotate(-15deg);
	}
	50% {
		transform: rotate(15deg);
	}
}

.Error .text-big {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	clip-path: circle(400px at 50% 50%);
	background-color: var(--black);
	display: grid;
	place-items: center;
}
.Error .text-big h1 {
	font-size: 14em;
	font-weight: 800;
	color: var(--black);
	text-shadow: -20px 20px 30px rgba(0, 0, 0, 0.8);
	line-height: 1em;
	animation: move-shadow 4s infinite ease-in-out;
}
@keyframes move-shadow {
	0%,
	100% {
		text-shadow: 20px 20px 30px rgba(0, 0, 0, 0.8);
	}
	50% {
		text-shadow: -20px 20px 30px rgba(0, 0, 0, 0.8);
	}
}

.Error .info {
	color: var(--white);
	width: 400px;
	text-align: center;
	align-self: center;
	position: absolute;
	bottom: 20%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 5;
}
.Error .info h2 {
	font-weight: normal;
	color: #c9c9c9;
}
.Error .info p {
	font-size: 0.8em;
	margin: 20px 0;
	color: #a5a5a5;
	line-height: 24px;
}
.Error .info button {
	font-size: 0.8em;
	text-transform: uppercase;
	padding: 10px 30px;
	background-color: #919191;
	border: none;
	border-radius: 500px;
	letter-spacing: 2px;
	color: #333333;
	font-weight: 600;
}

.Error footer {
	position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 0.8rem;
	color: var(--black);
	z-index: 7;
}
.Error footer a {
	text-decoration: none;
	color: #ea4c89;
}
