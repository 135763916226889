.NewCoursesCard {
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh; */
  flex-wrap: wrap;
}

.NewCoursesCard .container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 200px;
  height: 250px;
  margin: 1rem;
  background-position: center;
  /* box-shadow: 2px 8px 6px #1b1b1b; */
  overflow: hidden;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.NewCoursesCard .container .card .text {
  font-size: 18px;
  color: #3c3434;
}
.NewCoursesCard .container img {
  object-fit: contain;
}

.NewCoursesCard .card {
  position: absolute;
  width: 100%;
  bottom: -35rem;
  height: 100%;
  color: #ffffff;
  text-align: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  transition: all 0.5s ease;
}

.NewCoursesCard .container:hover .card {
  bottom: 0rem;
}

.NewCoursesCard .text {
  /* font-size: 25px; */
  transition: all 0.5s ease;
}
.NewCoursesCard h5 {
  margin: 0 1rem;
  color: gray;
}
.NewCoursesCard button {
  padding: 0.5rem;
  background: #3c3434;
  border: none;
  color: #fff;
  margin: 1rem;
  cursor: pointer;
  border-radius: 5px;
}
