.overview-wrapper img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 5px;
  border: 1px solid gray;
}
.overview-wrapper .overview-detail {
  padding: 0 0.5rem;
}
