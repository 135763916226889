.Footer {
  /* height: 300vh; */
  margin: auto;
  /* width: 100%; */
  background: linear-gradient(to right, #ffffff, #b4e9bc);
  color: green;
  padding: 0.5rem;
  border-top-left-radius: 125px;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden !important;
}

.Footer .row {
  margin: auto;
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden !important;
}
.Footer .row .col {
  flex-basis: 22%;
  padding: 10px;
  overflow: hidden !important;
}
.Footer .row .col:nth-child(2),
.Footer .row .col:nth-child(3) {
  flex-basis: 15%;
}
.Footer .row .col .cover {
  text-align: center;
  color: green;
  padding: 0.5rem;
}
.Footer .row .col .cover2 {
  /* text-align: center; */
  color: green;
  font-weight: bold;
  /* padding: 0.5rem; */
}
.Footer .row .col .cover img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  border-radius: 50px;
}
.Footer .row .col .cover p {
  text-align: left;
  color: green;
  font-size: 10px;
}

.Footer .row .col h3 {
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
  font-weight: bolder;
}
.Footer .row .col .email-id {
  width: fit-content;
  border-bottom: 1px solid white;
  margin: 20px 0;
  color: green;
  font-weight: bold;
  font-size: 16px;
}

.Footer .row .col ul li {
  list-style: none;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  color: green;
  text-align: left;
}
.Footer .row .col ul li .Link {
  color: green;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.1rem;
  margin-top: 0.5rem;
}

.Footer .row .col form .inputWrapper {
  /* padding-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}
.Footer .row .col form .inputWrapper .Env {
  font-size: 26px;
  /* margin-right: 10px; */
  background: transparent;
  border-radius: 50%;
  /* padding: 0.5rem; */
  color: black;
}
.Footer .row .col form .inputWrapper .send {
  font-size: 26px;
  /* margin-right: 10px; */
  background: white;
  border-radius: 50%;
  padding: 0.3rem;
  color: green;
  border: 2px solid greenyellow;
}
.Footer .row .col form .inputWrapper input {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  color: green;
}
.Footer .row .col form .inputWrapper button {
  /* width: 70%; */
  padding: 0.5rem;
  font-size: 18px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.Footer .row .col .social-icons {
  display: flex;
  justify-content: space-between;
}
.Footer .row .col .social-icons .social {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 0.5rem;
  border: 3px solid white;
  color: green;
}
.Footer .copyright {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: green;
}
.Footer hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid white;
  margin: 20px auto;
}
.Footer .underline {
  width: 100%;
  height: 5px;
  background: green;
  border-radius: 3px;
  position: absolute;
  top: 25px;
  left: 0;
  overflow: hidden;
}
.Footer .underline span {
  width: 15px;
  height: 100%;
  background: rgb(173, 230, 173);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 10px;
  animation: moving 4s linear infinite;
}

@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 700px) {
  .Footer .row .col {
    flex-basis: 100%;
  }
  .Footer .row .col:nth-child(2),
  .Footer .row .col:nth-child(3) {
    flex-basis: 100%;
  }
}
