.Login .contanier {
  margin: 1rem auto;
  width: 400px;
  background-color: #85ee9c3e;
  border: 0px solid #00000066;
  /* height: 100%; */
  border-radius: 38px;
  padding: 25px 35px;
}
.Login .contanier h1 {
  text-align: center;
  width: auto;
  font-size: 16px;
}
.login {
  display: flex;
  flex-direction: column;
  /* width: auto; */
  padding: 0 10px;
  /* margin-top: 35px; */
}
.login #userLabel,
.login #passLabel {
  font-size: 12px;
  padding-left: 2px;
  margin-bottom: 2px;
}
.login .usInput,
.login .pwInput {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 25px;
  border: none;
  background-color: #ffffff00;
  border-bottom: 1px solid #8080805e;
}
.login .usInput,
.login .pwInput:focus {
  outline: none;
}

.login p {
  align-self: flex-end;
  font-size: 10px;
  /* cursor: pointer; */
  font-weight: bold;
  color: gray;
}
.Login .contanier #login-Button {
  margin: 25px auto;
  display: block;
  width: 200px;
  line-height: 35px;
  border-radius: 50px;
  border: 0px solid white;
  background-image: linear-gradient(
    to right,
    rgba(29, 26, 26, 0.82),
    rgba(233, 30, 99, 0.86)
  );
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.Login .contanier .or {
  position: relative;
  padding: 0 10px;
  color: #000000;
  margin: 10px 0;
}
.Login .contanier .or p {
  border: 1px solid #00000059;
  border-radius: 3px;
  background-color: #000000c4;
  text-align: center;
  width: 25px;
  position: absolute;
  top: -10px;
  right: 150px;
}
.Login .contanier .options {
  display: flex;
  list-style: none;
  font-size: 30px;
  justify-content: center;
  margin-top: 40px;
  gap: 5px;
  margin-bottom: 30px;
  cursor: pointer;
}
.Login .contanier .options #F {
  color: #0b84ed;
  background-color: white;
  border-radius: 40px;
}
.Login .contanier .options #S {
  background-color: #1d9bf0;
  border-radius: 14px;
  color: white;
  font-size: 20px;
  width: 32px;
  padding: 5px;
  text-align: center;
}
.Login .contanier .options #T {
  background-color: #c5221f;
  border-radius: 14px;
  color: white;
  font-size: 20px;
  width: 32px;
  padding: 5px;
  text-align: center;
}
.Login .contanier .signUp {
  padding: 0 10px;
  text-align: center;
  font-size: 16px;
  color: grey;
}
.Login .contanier .signUp .Link {
  color: rgb(4, 128, 22);
}
.Login .eye {
  cursor: pointer;
  font-size: 25px;
  padding: 0.2rem;
  border-radius: 50%;
  background: white;
}
.file-input {
  /* background: red; */
  position: relative;
  /* width: 100px; */
  margin: 1rem 0;
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
}
.pictureBtnUp {
  color: gray;
  padding: 0.2rem;
  font-size: 40px;
  border: 1px solid black;
  border-radius: 50%;
}

.input-file {
  display: none; /* Hide the default file input */
}

.custom-file-upload {
  display: inline-block;
  background-color: #000000; /* Button background color */
  color: black; /* Button text color */
  padding: 8px 20px; /* Adjust padding as needed */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
}
.centerPicture {
  display: flex !important;
  justify-content: center;
  text-align: center !important;
  /* background: red; */
  border-bottom: none !important;
}

@media screen and (max-width: 600px) {
  .Login form {
    margin: 0;
    padding: 0;
  }
  .Login .contanier {
    margin: 0rem auto;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0px solid #00000066;
    /* height: 100%; */
    border-radius: 38px;
    padding: 25px 35px;
  }
}

.dataHomeLink {
  font-size: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
}
