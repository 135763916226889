.Application-All {
  position: relative;
}
.return {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 1rem;
  border-radius: 50%;
  left: 1%;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0);
  color: white;
  z-index: 100;
}
.Application-All .cover {
  margin: 1rem auto;
  width: 80%;
  /* background: #f3f3f3; */
  display: flex;
  gap: 0 1rem;
  /* position: relative; */
}
.Application-All .cover .left {
  /* background: white; */
  width: 20%;
  /* min-height: 40vh; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  /* height: 100%; */
  /* justify-content: center; */
}
.Application-All .cover .left .wrapper2 {
  border-radius: 6px;
  /* background: green; */
  margin: 0 auto;
  width: 100%;
}
.Application-All .cover .left .wrapper2 .coverL {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* gap: 0.2rem; */
  /* background: red; */
}
.Application-All .cover .left .wrapper2 .coverL .pic img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
}
.Application-All .cover .left .wrapper2 .coverL .pdata {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  text-align: center;
}
.Application-All .cover .left .wrapper2 .coverL .pdata h4 {
  font-size: 10px;
}
.Application-All .cover .left .wrapper2 .coverL .pdata h3 {
  font-size: 13px;
  font-weight: bolder;
  color: rgb(87, 140, 7);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.3rem;
}
.Application-All .cover .left .wrapper2 .course-data {
  display: flex;
  width: 100% !important;
  gap: 0.1rem;
  /* background: red; */
}
.Application-All .cover .left .wrapper2 .course-data .my-course {
  width: 100%;
  font-size: 8px;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
}
.Application-All .cover .left .wrapper2 .course-data .my-course .show {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Application-All .cover .wrapper2 .course-roll {
  display: grid;
  /* flex-direction: column; */
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* gap: .2rem; */
  /* justify-content: start; */
  /* background: red; */
}
.Application-All .cover .wrapper2 .course-roll .pic img {
  width: 50px;
  height: 50px;
  /* margin: auto; */
  border-radius: 50%;
  object-fit: cover;
  /* border-radius: 6px; */
}
.Application-All .cover .wrapper2 .course-roll .data h3 {
  font-weight: bolder;
  color: rgb(47, 176, 14);
  font-size: 10px;
}
.Application-All .cover .wrapper2 .course-roll .data h4 {
  font-weight: bolder;
  color: rgb(120, 68, 77);
  font-size: 7px;
}

.Application-All .cover .center {
  width: 60%;
  /* background: black; */
  /* min-height: 40vh; */
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* margin: 2rem 0; */
  padding: 2rem 0;
  /* justify-content: center; */
}
.Application-All .cover .center .search-bar {
  /* padding: 1rem; */
  /* background: gray; */
  width: 40%;
  margin: 0 auto;
  position: fixed;
  z-index: 100;
  top: 0;
  /* top: 1rem; */
}
.Application-All .cover .center .search-bar input {
  padding: 1rem 0;
  /* background: gray; */
  width: 100%;
  margin: 1rem auto;
  outline: none;
  border-radius: 50px;
  border: 1px solid gray;
  text-align: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.Application-All .cover .center .blogs {
  /* background: black; */
  width: 100%;
  overflow: hidden;
  padding: 1rem;
  margin-top: 5rem;
}
.Application-All .cover .center .blogs .news {
  /* background: black; */
  width: 70%;
  margin: auto;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.Application-All .cover .center .blogs .news .top {
  /* background: red; */
  width: 100%;
  /* margin: auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* justify-content: center; */
}
.Application-All .cover .center .blogs .news .top .imageP {
  padding: 0.5rem;
}
.Application-All .cover .center .blogs .news .top .imageP img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.Application-All .cover .center .blogs .news .top .centerData {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.Application-All .cover .center .blogs .news .top .centerData h3 {
  font-size: 12px;
  color: rgb(58, 57, 57);
  font-weight: bold;
}
.Application-All .cover .center .blogs .news .top .centerData h4 {
  font-size: 10px;
  color: gray;
}
.Application-All .cover .center .blogs .news .top .options {
  top: 0.2rem;
  border-radius: 50px;
  position: absolute;
  right: 0.2rem;
  width: 80px;
  height: 20px;
  background: gray;
  display: flex;
  color: white;
  justify-content: space-evenly;
  align-items: center;
}
.post img {
  width: 100%;
  object-fit: contain;
  height: 150px;
  /* display: flex; */
  /* justify-content: space-evenly;
  /* align-items: center; */
  /* padding: 0.2rem; */
  /* margin: auto; */
  border-radius: 11px;
}
.Application-All .cover .center .blogs .news .optionsData {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem auto;
}
.Application-All .cover .center .blogs .news .optionsData .icons img {
  width: 40px;
  height: 40px;
  /* background: rgb(236, 236, 236); */
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid gray;
}

.Application-All .cover .right {
  width: 20%;
  /* background: white; */
  /* min-height: 40vh; */
  /* border-radius: 6px; */
  /* margin-top: 1rem; */
  position: -webkit-sticky; /* for browser compatibility */
  position: sticky;
  top: 0;
}
.Application-All .cover .right h3 {
  color: red;
}
.Application-All .cover .right .container {
  display: flex;
  /* height: 100%; */
  justify-content: center;
  /* align-items: center; */
  /* background: red; */
  padding: 0.2rem;
}

.Application-All .cover .right .rectangle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 50px;
  height: 50px;
  background: #0dc063;
  transform: scale(0);
  border-radius: 50%;
  color: rgb(132, 72, 107);
  opacity: 0;
  overflow: hidden;
  animation: scale-in 0.3s ease-out forwards,
    expand 0.35s 0.25s ease-out forwards;
}

.Application-All .cover .right .notification-text {
  display: flex;
  width: 100%;
  gap: 0.3rem;
  /* text-align: left; */
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  animation: fade-in 0.65s ease-in forwards;
}

@keyframes scale-in {
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes expand {
  50% {
    width: 350px;
    border-radius: 6px;
  }
  100% {
    width: 300px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -1px rgba(0, 0, 0, 0.12);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

@media screen and (max-width: 1000px) {
  .Application-All .cover {
    margin: 1rem auto;
    width: 80%;
    /* background: #f3f3f3; */
    display: flex;
    flex-direction: column;
    gap: 0 1rem;
    /* position: relative; */
  }
  .Application-All .cover .left {
    /* background: red; */
    width: 100%;
    /* min-height: 40vh; */
    display: flex;
    /* flex-direction: column; */
    overflow: hidden;
    align-items: center;
    padding: 0.1rem;
    /* height: 100%; */
    justify-content: center;
  }
  .Application-All .cover .left .wrapper2 .coverL {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* gap: 0.2rem; */
    /* background: red; */
  }
  .Application-All .cover .left .wrapper2 {
    border-radius: 6px;
    /* background: green; */
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .Application-All .cover .wrapper2 .course-roll {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* gap: .2rem; */
    /* justify-content: start; */
    /* background: red; */
  }
  .Application-All .cover .left .wrapper2 .course-data {
    display: flex;
    width: 100% !important;
    gap: 0.1rem;
    align-items: center;
    /* background: red; */
  }
  .Application-All .cover .left .wrapper2 .course-data .my-course {
    width: 100%;
    font-size: 8px;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
  }
  .Application-All .cover .left .wrapper2 .course-data .my-course .show {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Application-All .cover .center {
    width: 100%;
    /* background: black; */
    /* min-height: 40vh; */
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    /* justify-content: center; */
  }
  .Application-All .cover .right {
    width: 100%;
    background: white;
    text-align: center;
    /* min-height: 40vh; */
    /* border-radius: 6px; */
    /* margin-top: 1rem; */
    position: -webkit-sticky; /* for browser compatibility */
    position: sticky;
    top: 0;
  }
  .Application-All .cover .center .search-bar {
    /* padding: 1rem; */
    /* background: gray; */
    width: 70%;
    margin: 0 auto;
    position: fixed;
    z-index: 100;
    /* top: 1rem; */
    /* visibility: hidden; */
  }

  .Application-All .cover .center .blogs {
    /* background: black; */
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin-top: 1rem;
  }
  .Application-All .cover .center .blogs .news {
    /* background: black; */
    width: 90%;
    margin: auto;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
}
