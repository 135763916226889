.Banner-sec {
  width: 100%;
  background: var(--background-color);
  padding: 1rem 2rem;
  position: relative;
}

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:wght@900&display=swap");

.Banner-sec .hero {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  margin: auto;
  padding: 1rem;
}

.Banner-sec .hero__content h3 {
  margin-top: -15px;
  color: #4475e2;
}

.Banner-sec .hero__content hr {
  width: 130px;
  margin: -10px 0 0 0;
}

.Banner-sec .btn {
  border: none;
  outline: none;
  padding: 15px 45px;
  background-color: transparent;
  border-radius: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s;
}
.Banner-sec .btn:hover {
  transform: translateY(5px);
}

.Banner-sec .btn--contact {
  background-color: #020d1a;
}

.Banner-sec .btn--contact:hover {
  background-color: var(--active-color);
}

.Banner-sec .btn--resume {
  border: 2px solid green;
  margin-left: 10px;
  color: var(--active-color);
}

.Banner-sec .btn--resume:hover {
  background-color: #010818;
  color: #fff;
}

.Banner-sec .social {
  display: flex;
  justify-content: center;
  margin-top: -4rem;
  transform: translate(0px, 80px);
  align-items: center;
}

.Banner-sec .social .bx {
  font-size: 35px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.Banner-sec .social .bx:hover {
  transform: translateY(5px);
}

.Banner-sec .hero__img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Banner-sec .hero__img img {
  width: 70%;
  height: 300px;
  object-fit: contain;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  border: 5px solid black;
}

@media screen and (max-width: 1090px) {
  .Banner-sec {
    padding: 1rem 0rem;
  }
  .Banner-sec .hero {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin: auto;
    padding: 0.1rem;
    /* background: black; */
  }

  .Banner-sec .hero__content h3 {
    margin-top: -15px;
    color: #4475e2;
  }
  .Banner-sec .btn {
    border: none;
    outline: none;
    padding: 15px 0;
    background-color: transparent;
    border-radius: 50px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .Banner-sec .hero__img img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    border-radius: 10px;
    border: none;
  }
}
@media screen and (max-width: 600px) {
  .Banner-sec .hero__content h1 {
    font-size: 18px;
  }
  .Banner-sec .hero__content h3 {
    font-size: 10px;
  }
  .Banner-sec .hero__content p {
    font-size: 10px;
  }
}
