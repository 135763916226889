.progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd; /* Default icon background */
  color: white;
}

.icon-container.completed {
  background-color: green; /* Completed step icon background */
}

@keyframes line-fill-animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #ddd; /* Color for the unfilled part of the line */
  position: relative;
}

.line::after {
  content: "";
  display: block;
  height: 100%;
  width: 0; /* Start with no filled width */
  background-color: green; /* Color for the filled part of the line */
  position: absolute;
}

.line.completed::after {
  animation: line-fill-animation 0.5s ease forwards; /* Ensure 'forwards' is here */
}
