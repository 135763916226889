body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10 and IE 11 */

  background-color: var(--theme-bg-color);
  font-family: var(--body-font);
  font-size: 15px;
  color: var(--body-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  outline: none;
  box-sizing: border-box;
}

:root {
  --body-bg-color: #e5ecef;
  --theme-bg-color: #fafafb;
  --body-font: "Poppins", sans-serif;
  --body-color: #2f2f33;
  --active-color: rgb(14, 181, 33);
  --active-light-color: #e1ebfb;
  --header-bg-color: #fff;
  --search-border-color: #efefef;
  --border-color: #d8d8d8;
  --alert-bg-color: #e8f2ff;
  --subtitle-color: #83838e;
  --inactive-color: #f0f0f0;
  --placeholder-color: #9b9ba5;
  --time-button: #57fcca;
  --level-button: #c4115f;
  --button-color: #fff;
}

.dark-mode {
  --body-bg-color: #1d1d1d;
  --theme-bg-color: #13131a;
  --header-bg-color: #1c1c24;
  --alert-bg-color: #292932;
  --body-color: #fff;
  --inactive-color: #292932;
  --time-button: #fff;
  --level-button: #fff;
  --active-light-color: #263d63;
  --border-color: #26262f;
  --search-border-color: #26262f;
}

img {
  max-width: 100%;
  object-fit: cover;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  scroll-behavior: smooth;
}

* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}
