@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

.Lecturers {
  margin: 2rem auto;
  width: 60%;
}
.Lecturers h3 {
  margin: 1rem auto;
  text-align: center;
  width: 50%;
  padding: 0.5rem;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.Lecturers .card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.Lecturers .card {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(212, 212, 212, 0.3);
  color: gray;
  /* border-radius: 0.3125em; */
  /* box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4); */
  padding: 1.25em 1.5625em;
  text-align: center;
}

.Lecturers .card img {
  border-radius: 50%;
  border: 0.3125em solid #f0f0f0;
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.Lecturers .card h1 {
  font-size: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.Lecturers .card p {
  margin-top: auto;
  font-size: 0.8rem;
  margin-bottom: 3.4375em;
}

.Lecturers input[type="button"] {
  background-color: greenyellow;
  color: #7f7496;
  border: 2px solid #1d0e2e;
  border-radius: 3.125em;
  padding: 0.625em 3.125em;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 640px) {
  .Lecturers {
    margin: 2rem auto;
    width: 95%;
  }
  .Lecturers .card-wrapper {
    grid-template-columns: 1fr;
  }
}
